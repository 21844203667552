<template>
  <CardConf
    :title="$tc('calendar')"
    :icon="'$calendar1'"
    :size="500"
    :keys="'calendar_active'"
    :activeted="true"
    ref="calendari"
  >
    <template>
      <v-row align="center">
        <v-col cols="6" md="6">
          {{ $t("timetable", { name: $tc("studio") }) }}
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="5" md="5" xl="4" class="pt-4">
          <timetable :studio_id="user.studio.id"></timetable>
        </v-col>
        <v-col cols="6" md="6">
          {{ $t("close_days") }}
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="5" md="5" xl="4" class="pt-4">
          <CloseDays :id="user.id"></CloseDays>
        </v-col>
        <v-col cols="6" md="7">
          {{ $t("calendar_default") }}
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="5" md="5" xl="4" class="">
          <CalendarConfiguration visible />
          <!--<v-select
            :items="$store.getters['auth/googleAccounts']"
            single-line
            dense
            outlined
            :disabled="
              $store.getters['auth/googleAccounts'] === false ||
              $store.getters['auth/googleAccounts'].length === 1
            "
            item-value="user.email"
            hide-details
            v-model="calendar_id"
            ><template slot="selection" slot-scope="data">
              {{ data.item.user.email }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.user.email }}
            </template>
          </v-select>-->
          <v-tooltip v-if="!$vuetify.breakpoint.mdAndDown" bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                style="position: absolute; right: 8px"
                v-bind:style="[
                  {
                    top: $vuetify.breakpoint.xsOnly
                      ? '210px'
                      : $vuetify.breakpoint.smOnly
                      ? '195px'
                      : $vuetify.breakpoint.mdOnly
                      ? '120px'
                      : '195px',
                  },
                ]"
                color="red"
                v-on="on"
                @click="
                  $emit('changeTab');
                  $refs.card.dialog = false;
                "
                >mdi-information-outline</v-icon
              >
            </template>
            <span v-if="$store.getters['auth/googleAccounts'] === false"
              >Para poder seleccionar un calendario sincronizado debes vincular
              un GMAIL</span
            >
            <span style="font-size: 12px" v-else>
              El GMAIL seleccionado se sincronizará con el calendario interno de
              QUICKINK.
            </span>
          </v-tooltip>
          <v-menu
            v-if="$vuetify.breakpoint.mdAndDown"
            :close-on-content-click="true"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                style="position: absolute; right: 8px"
                v-bind:style="[
                  {
                    top: $vuetify.breakpoint.xsOnly
                      ? '210px'
                      : $vuetify.breakpoint.smOnly
                      ? '195px'
                      : $vuetify.breakpoint.mdOnly
                      ? '195px'
                      : '195px',
                  },
                ]"
                color="red"
                v-on="on"
                @click="
                  $emit('changeTab');
                  $refs.card.dialog = false;
                "
                >mdi-information-outline</v-icon
              >
            </template>
            <p
              class="py-3 mb-0 px-5"
              v-if="$store.getters['auth/googleAccounts'] === false"
            >
              Para poder seleccionar un calendario sincronizado debes vincular
              un GMAIL
            </p>
            <p class="py-3 mb-0 px-5" style="font-size: 12px" v-else>
              El GMAIL seleccionado se sincronizará con el calendario interno de
              QUICKINK.
            </p></v-menu
          >
        </v-col>
      </v-row>
    </template>
  </CardConf>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CalendarCard",
  components: {
    CloseDays: () => import("@/components/user/settings/profile/CloseDays"),
    timetable: () => import("@/components/user/timetable/Configuration"),
    CardConf: () => import("@/components/user/settings/Card"),
    CalendarConfiguration: () =>
      import("@/components/user/settings/connection/CalendarConfiguration"),
  },

  methods: {
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    edit(valor) {
      this.disabled = valor;
    },
  },
  mounted() {
    this.$refs.calendari.dialog = this.$route.params.calendar ? true : false;
  },
  data() {
    return {
      disabled: false,
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.state.auth.user;
      },
      set(value) {
        //console.log("setting");
        this.$store.commit("auth/UPDATE_USER", value);
      },
    },
    calendar_id: {
      get() {
        return this.$store.getters["auth/getSetting"]("calendar_id");
      },
      set(value) {
        //llamamos a la funcion de crear setting
        this.settingUpdate({ key: "calendar_id", value }).then((data) => {
          this.$alert(this.$t("save_ok"));
          //console.log(data);
        });
      },
    },
  },
};
</script>

<style lang="sass">
.v-select__selections
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
</style>