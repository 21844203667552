var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CardConf',{ref:"calendari",attrs:{"title":_vm.$tc('calendar'),"icon":'$calendar1',"size":500,"keys":'calendar_active',"activeted":true}},[[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6","md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("timetable", { name: _vm.$tc("studio") }))+" ")]),_c('v-spacer'),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"5","md":"5","xl":"4"}},[_c('timetable',{attrs:{"studio_id":_vm.user.studio.id}})],1),_c('v-col',{attrs:{"cols":"6","md":"6"}},[_vm._v(" "+_vm._s(_vm.$t("close_days"))+" ")]),_c('v-spacer'),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"5","md":"5","xl":"4"}},[_c('CloseDays',{attrs:{"id":_vm.user.id}})],1),_c('v-col',{attrs:{"cols":"6","md":"7"}},[_vm._v(" "+_vm._s(_vm.$t("calendar_default"))+" ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"5","md":"5","xl":"4"}},[_c('CalendarConfiguration',{attrs:{"visible":""}}),(!_vm.$vuetify.breakpoint.mdAndDown)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"position":"absolute","right":"8px"},style:([
                {
                  top: _vm.$vuetify.breakpoint.xsOnly
                    ? '210px'
                    : _vm.$vuetify.breakpoint.smOnly
                    ? '195px'
                    : _vm.$vuetify.breakpoint.mdOnly
                    ? '120px'
                    : '195px',
                } ]),attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,2173329745)},[(_vm.$store.getters['auth/googleAccounts'] === false)?_c('span',[_vm._v("Para poder seleccionar un calendario sincronizado debes vincular un GMAIL")]):_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" El GMAIL seleccionado se sincronizará con el calendario interno de QUICKINK. ")])]):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"position":"absolute","right":"8px"},style:([
                {
                  top: _vm.$vuetify.breakpoint.xsOnly
                    ? '210px'
                    : _vm.$vuetify.breakpoint.smOnly
                    ? '195px'
                    : _vm.$vuetify.breakpoint.mdOnly
                    ? '195px'
                    : '195px',
                } ]),attrs:{"color":"red"},on:{"click":function($event){_vm.$emit('changeTab');
                _vm.$refs.card.dialog = false;}}},on),[_vm._v("mdi-information-outline")])]}}],null,false,2103092991)},[(_vm.$store.getters['auth/googleAccounts'] === false)?_c('p',{staticClass:"py-3 mb-0 px-5"},[_vm._v(" Para poder seleccionar un calendario sincronizado debes vincular un GMAIL ")]):_c('p',{staticClass:"py-3 mb-0 px-5",staticStyle:{"font-size":"12px"}},[_vm._v(" El GMAIL seleccionado se sincronizará con el calendario interno de QUICKINK. ")])]):_vm._e()],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }